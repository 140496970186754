import { useParams } from 'react-router-dom';
import { SingleColumnContent } from '../../components/layout/Layout';
import { IFinancialSolutionRouteParams } from '../../interfaces/IRouteParams';
import { FinancialSolutionsContent, FinancialSolutionType } from './FinancialSolutionsContent';
import FAQs from '../../components/ui/faqs/FAQs';
import Embed from '../../components/ui/embed/Embed';
import Breadcrumbs from '../../components/ui/breadcrumbs/Breadcrumbs';
import { PATHS } from '../../components/routes/paths';
import { HomeSearchLinkComponent } from './FinancialSolutions';
import Button from '../../components/ui/button/Button';
import ApplyLoanButton from '../../components/ui/button/ApplyLoanButton';
import { sendEvent } from '../../features/analytics/sendEvent';
import { log } from '../../utils/logger';
import Modal from '../../components/ui/modal/Modal';
import { useState } from 'react';
import './FinancialSolution.scss';
import Spinner from '../../components/ui/spinner/Spinner';
import cn from 'classnames';

const FinancialSolution = () => {
  const { solutionId } = useParams<IFinancialSolutionRouteParams>();
  const solutionsMap = FinancialSolutionsContent;
  const solution = solutionsMap[solutionId as FinancialSolutionType];
  const { title, description, faq, videoUrl, applicationButtonText = 'Apply now', applicationButtonUrl, disclosuresUrl } = solution;
  const [disclosuresOpen, setDisclosuresOpen] = useState(false);
  const [disclosuresLoading, setDisclosuresLoading] = useState(true);
  const faqNodes = faq?.map(f => {
    return {
      question: f.question,
      answer: <p dangerouslySetInnerHTML={{ __html: f.answer as string }}/>,
    };
  });

  const breadcrumbs = [
    { text: 'Financial solutions', path: PATHS.financialSolutions },
    { text: title, path: PATHS.financialSolution.replace(':solutionId', solutionId) },
  ];

  const onApplyClick = () => {
    sendEvent(`startAnApplication: financial solution ${solutionId}`);
    log({ level: 'info', message: `startAnApplication: financial solution ${solutionId}` });
  };

  const onDisclosuresOpen = (open: boolean) => {
    setDisclosuresOpen(open);
    if (open) {
      setDisclosuresLoading(true);
    }
  };

  const onDisclosuresLoad = () => {
    setDisclosuresLoading(false);
  };

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <SingleColumnContent>
        <h1 className='mb-4 mt-2 text-marketing-xs md:text-marketing-md'>{title}</h1>
        <p className='mb-6'>{description}</p>
        <div className='flex flex-col items-center justify-center'>
          {videoUrl &&
            <>
              <Embed
                className='block md:hidden'
                title={`Video: ${title}`}
                src={videoUrl}
                width='100%'
                // in mobile - screen with minus x-padding * 0.56 as 16:9 aspect ratio
                style={{ height: 'calc((100vw - 48px) * 0.56)' }}
              />
              <Embed
                className='hidden md:block'
                title={`Video: ${title}`}
                src={videoUrl}
                width='675'
                height='380'
              />
            </>
          }
          {applicationButtonUrl ?
            <Button
              buttonType='primary'
              text={applicationButtonText}
              className='mt-6 w-fit-content'
              href={applicationButtonUrl}
              target='_blank'
            />
            :
            <ApplyLoanButton className='mt-6' buttonType='primary' onClick={onApplyClick} />
          }
        </div>
        <HomeSearchLinkComponent className='my-6' />
        {faqNodes && <FAQs heading='FAQs' faqs={faqNodes} />}
        {disclosuresUrl &&
          <div className='mt-4'>
            <h2>Additional links</h2>
            <Button size='small' buttonType='tertiary' text='*Marketing disclosures' onClick={() => onDisclosuresOpen(true)} />
          </div>
        }
        {disclosuresUrl &&
          <Modal contentLabel='Marketing disclosure' open={disclosuresOpen} onRequestClose={() => onDisclosuresOpen(false)}>
            <>
              {disclosuresLoading && <Spinner />}
              <Embed className={cn('w-full marketing_disclosure_iframe', { hidden: disclosuresLoading } )} title='Marketing disclosure' src={disclosuresUrl} onLoad={onDisclosuresLoad} />
            </>
          </Modal>
        }
      </SingleColumnContent>
    </>
  );
};

export default FinancialSolution;
