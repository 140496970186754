import homeLoansImage from '../../images/fs-home-loans.png';
import sameDayImage from '../../images/fs-same-day.png';
import helocImage from '../../images/fs-heloc.png';
import reverseMortgageImage from '../../images/fs-reverse-mortgage.png';
import personalLoansImage from '../../images/fs-personal-loans.png';
import insuranceImage from '../../images/fs-insurance.png';
import refinanceImage from '../../images/fs-refinance.png';
import cashoutRefinanceImage from '../../images/fs-cash-out-refi.png';
import { IFAQ } from '../../components/ui/faqs/FAQs';

export interface FinancialSolution { 
  title: string;
  shortDescription: string;
  iconImage: string;
  description?: string;
  solutionId: string;
  applicationButtonText?: string;
  applicationButtonUrl?: string;
  videoUrl?: string;
  disclosuresUrl?: string;
  faq?: IFAQ[];
}

export const FinancialSolutionIds = [
  'homeLoans',
  'heloc',
  'personalLoans',
  'refinance',
  'sameDayMortgage',
  'reverseMortgage',
  'insurance',
  'cashoutRefinance',
] as const;
export type FinancialSolutionType = typeof FinancialSolutionIds[number];

export const FinancialSolutionsContent: Record<FinancialSolutionType, FinancialSolution> = {
  homeLoans: {
    title: 'Home loans',
    shortDescription: 'Find the perfect loan for you',
    iconImage: homeLoansImage,
    description: 'Everyone deserves a chance to live the American Dream with a place of their own. Whether you need more space for work or play, wish to start a family, or just want to get out of the renting game, there\'s nothing like a new home.',
    solutionId: 'homeLoans',
    applicationButtonText: 'Apply now',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862167897/rendition/1080p/file.mp4?loc=external&signature=1d0f56bc318932f3c12665cb0225b965d5ae6302dfcc030c8d47e98b5f2fb83b',
    disclosuresUrl: 'https://www.rate.com/app/same-day-mortgage-disclaimer',
    faq: [
      {
        question: 'What is a loan?',
        answer: 'A loan is a generic term used to describe an arrangement where a borrower receives money from a lender and the borrower agrees to pay the lender back.'
      },
      {
        question: 'What is a mortgage?',
        answer: 'A mortgage is an agreement between you and a lender to purchase property with money provided by the lender. The agreement requires you to repay the money you\'ve borrowed plus interest and, if you fail to meet the terms of the mortgage, gives the lender the right to take your property.'
      },
      {
        question: 'What is a home loan or mortgage loan?',
        answer: 'A home loan or mortgage loan is a loan to buy a home or to borrow money against the value of the home you already own.'
      },
      {
        question: 'What types of mortgage programs are available to borrowers?',
        answer: 'Foreclosed homes run the gamut of \'diamonds in the rough\' to \'money pits.\' If you know your way around a set of tools and want to renovate the place, they can be very successful purchases. However, you should know more than the fundamentals of homebuying before investing.'
      },
      {
        question: 'What is the mortgage loan process?',
        answer: 'While there are many different types and variations of mortgage loans, the process from start to finish tends to involve the following six steps: · Loan processing: You submit your loan application, along with any required documentation. Your loan officer will guide you through this part of the process.<br><br><ul><li>Underwriting review: Your application is reviewed to ensure you can afford the loan and that the property is worth the loan amount.<br><br></li><li>Conditional approval: Your loan has passed the initial review by the Underwriting Department, but there may be additional information they need to make a final decision.<br><br></li><li>Clear to close: Your loan has been approved, and the lender is moving forward with any final preparations for you to close your loan. · Closing: All of the necessary documentation is readied for you to sign to close your loan.<br><br></li><li>Loan funded: After all closing documents have been approved, the money for the loan arrives, usually in the form of a wire transfer or a cashier\'s check. You get your keys, and you\'re ready to enjoy your new home!</li></ul>'
      },
      {
        question: 'How do I get a mortgage loan started?',
        answer: 'Hit the red \'Get Started\' button above to start your personal homeownership journey, or call us at (773) 435-0654. We\'ll get you on the road to owning the home of your dreams by helping you every step of the way.'
      },
      {
        question: 'Do I need a down payment?',
        answer: 'Not necessarily. You may qualify for a variety of different mortgage loan programs that require no money down. For more information about available programs and qualifications, talk to one of our expert loan officers. While a 20% or more down payment is great if you have it, it\'s a myth that you need that much to apply for a mortgage.'
      },
      {
        question: 'What type of mortgage loan should I apply for?',
        answer: 'There are many factors which can influence what type of mortgage loan you might pursue, including (but not limited to):<br><br><ul><li>How long you plan to stay in the new property<br><br></li><li>If you are a first-time homebuyer<br><br></li><li>If you are a veteran or are part of a veteran\'s immediate family<br><br></li><li>Your employment type<br><br></li><li>What kind of interest rate you need<br><br></li><li>How much you have for a down payment<br><br></li><li>If this will be your primary residence</li></ul><br>If you are unsure of what loan type would work best for your situation, talk to one of our expert loan officers. They can help you understand all the loan options that you have available to you.'
      },
      {
        question: 'How much of a home can I afford?',
        answer: 'No one wants to fall in love with a home they can\'t afford. To avoid this, take a look at the Affordability Calculator on this app. Once you have a better idea of your financial range, you\'ll want to talk to your lender about getting preapproved. A pre-approval is a letter or statement from a lender that explains how much money the lender can lend to you, based on certain assumptions about your credit history and credit worthiness.'
      },
      {
        question: 'Do I need perfect credit to get a home loan?',
        answer: 'No. You\'ll want your credit score to be in as good a shape as you can get it, to open up as many options for you as possible. Some of our loan programs have a minimum credit score of 620.'
      }
    ]
  },
  sameDayMortgage: {
    title: 'Same Day Mortgage',
    shortDescription: 'Get approved in 1 day and close fast',
    iconImage: sameDayImage,
    description: 'Same Day Mortgage is a program offered by Guaranteed Rate, Inc. Same Day Mortgage* gives eligible customers the potential to receive a loan approval on their mortgage loan within as little as 1 business day and close in 10 days. This is significantly faster than the traditional closing average of 45 days**, allowing you to compete with all-cash buyers.',
    solutionId: 'sameDayMortgage',
    applicationButtonText: 'Apply now',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862168062/rendition/1080p/file.mp4?loc=external&signature=0a4b07660a098eace81fe63dd53bed78e0f628ae7a071270d9c88638ce624c14',
    disclosuresUrl: 'https://www.rate.com/app/same-day-mortgage-disclaimer',
    faq: [
      {
        question: 'What\'s the difference between Same Day Mortgage and other mortgage programs?',
        answer: 'The short answer: speed. If your loan is eligible for Same Day Mortgage, we put your mortgage loan application in the fast lane, and if approved, you\'ll see your loan approval within 1 business day, and can close in 10 days.',
      },
      {
        question: 'Why does speed matter when getting a mortgage?',
        answer: 'Being able to close sooner rather than later is one of the biggest advantages in a highly contested housing market or bidding war. Homesellers will be eager to complete the sale as soon as possible, and being able to close quickly can make your bid competitive with all-cash offers.',
      },
      {
        question: 'What\'s the process of Same Day Mortgage vs. other mortgage programs?',
        answer: 'The process is pretty much the same—just faster. We use our proprietary, AI-driven algorithm to move automatically loans quickly if they\'re eligible. Once you have applied for a mortgageloan and uploaded all necessary documents, we\'ll take care of the rest.',
      },
      {
        question: 'Who is eligible for Same Day Mortgage?',
        answer: 'Everyone is eligible, but not all loans are. Our quick turn processing only works with mortgage loan applications that meet certain requirements. Once you\'ve provided all the necessary documentation requested for your mortgage loan, your loan officer can let you know if your loan qualifies for Same Day Mortgage.',
      },
      {
        question: 'How fast can I close on my home loan?',
        answer: 'Same Day Mortgage delivers qualified borrowers a loan approval in within 1 business day, and typically closes in 10 days.'
      },
      {
        question: 'What documentation do I need to provide?',
        answer: 'When you apply for your mortgage loan, you\'ll need to provide the following:<br><br></li><li>Proof of income including but not limited to W2 forms and/or tax returns<br><br></li><li>Evidence of assets<br><br></li><li>Property ownership documents (if any)<br><br></li><li>Miscellaneous other information, including (but not limited to):<br><br></li><li>Current contact information<br><br></li><li>Real estate/inspection/lawyer information<br><br></li><li>Employment history<br><br></li><li>Other information, as required to process your mortgage loan'
      },
    ],
  },
  heloc: {
    title: 'HELOC',
    shortDescription: 'User your home\'s equity',
    iconImage: helocImage,
    description: 'A HELOC (home equity line of credit) is a powerful tool for unlocking the financial potential of your home\'s equity. You can use the equity in your home for big projects like paying off debt, medical expenses, college tuition — you name it.',
    solutionId: 'heloc',
    applicationButtonText: 'Apply now',
    applicationButtonUrl: 'https://heloc-apply.rate.com/account/heloc/register',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862167859/rendition/720p/file.mp4?loc=external&signature=f9cc7ef5dcb1bde8cf8c4b61aa2297a5bd01700cf58f343c104be8b21afa1b69',
    disclosuresUrl: 'https://www.rate.com/app/heloc-product-disclosure',
    faq: [
      {
        question: 'What is home equity?',
        answer: 'Home equity is normally defined as the difference between the value of your home and the amount remaining on your mortgage and owed to your lender. For example, if your home is valued at $350,000 and you have $150,000 remaining on your mortgage, your home equity would be valued at $200,000.'
      },
      {
        question: 'What is a HELOC?',
        answer: 'A home equity line of credit, or HELOC, is somewhat similar to a credit card in that it\'s a revolving credit line. The difference is that a HELOC, with your home as collateral, converts your home equity into funds you can withdraw at any time, subject to the terms of the HELOC Agreement you enter into with your lender. HELOCs usually have a much lower interest rate than traditional credit cards.'
      },
      {
        question: 'What can I use a HELOC for?',
        answer: 'There is no restriction on what you can use the funds to buy. HELOCs are most commonly used for big or expensive projects that require a significant out-of-pocket expense. A short list includes but is not limited to:<br><br><ul><li>Debt consolidation<br><br></li><li>College tuition/continuing<br><br></li><li>Home improvement or repairs<br><br></li><li>Emergency or medical bills<br><br></li><li>Business expenses</li></ul>'
      },
      {
        question: 'What is the process of getting a HELOC?',
        answer: 'The first step to getting a HELOC is reaching out to a lender or loan officer. You\'ll start the process by applying and providing your personal information and income documentation. You\'ll also need information about your home\'s appraised value, as your property will be the collateral for the loan. Otherwise, obtaining a HELOC has many of the same milestones as a traditional home loan.'
      },
      {
        question: 'How much can I borrow with a HELOC?',
        answer: 'This will depend on the amount of equity you have built up in your home and on your qualifications including but not limited to your credit score, your debt-to-income ratio, and your property type. Usually, the limit for HELOC spending is between 80%-90% of your overall home equity.'
      }
    ]
  },
  reverseMortgage: {
    title: 'Reverse mortgage',
    shortDescription: 'Unlock your financial freedom now',
    iconImage: reverseMortgageImage,
    description: 'A reverse mortgage, also known as a home equity conversion mortgage (HECM), is a type of loan that allows homeowners aged 62 and older to turn their home\'s equity into usable cash. With a reverse mortgage, the lender pays the borrower.',
    solutionId: 'reverseMortgage',
    applicationButtonText: 'Get started',
    applicationButtonUrl: 'https://www.rate.com/preview/app/hecm-mortgage',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862167811/rendition/1080p/file.mp4?loc=external&signature=85dc46b06b9403b2698328e3b247aa26de74ddc42a67f6dd9a5682c6796d02f8',
    disclosuresUrl: 'https://www.rate.com/app/hecm-mortgage-disclaimer',
    faq: [
      {
        question: 'What is a reverse mortgage?',
        answer: 'Like a traditional mortgage, reverse mortgage loan allows homeowners to borrow money using their primary residence as security for the loan. Unlike a traditional mortgage, reverse mortgages do not require that borrowers make monthly payments. Rather, interest and fees are added to the loan balance each month and the balance grows. The loan is repaid when the borrower no longer lives in the home. A reverse mortgage is usually repaid by selling the home.'
      },
      {
        question: 'What types of reverse mortgages are available?',
        answer: 'There are two types of reverse mortgages: Home Equity Conversion Mortgages (HECM) and Proprietary Reverse Mortgages. A HECM is the most common type of reverse mortgage and allows homeowners aged 62 and older to turn their home\'s equity into usable cash. HECMs are insured by the federal government. Proprietary Reverse Mortgages are private mortgage programs with varying program requirements. Reverse mortgages are a great option for those looking to supplement their retirement without any monthly payments.<br><br>Do you want to learn more about reverse mortgages? Talk to one of our expert loan officers today.'
      },
      {
        question: 'Will I have a mortgage payment if I get a reverse mortgage?',
        answer: 'No. With a reverse mortgage, you have multiple options for disbursement of loan proceeds — your lender will send you the loan proceeds according to the loan structure you select. Please not that you are still responsible for the payment of home insurance premiums and all real estate taxes.'
      },
      {
        question: 'Who qualifies for a reverse mortgage?',
        answer: 'A HECM is available to homeowners who are aged 62 and older, who either own their property outright or have paid down the principal of their mortgage by a considerable amount. The owner must also use the property as their primary residence and not be delinquent on any federal debt.<br><br>Eligibility for proprietary reverse mortgage products varies — talk to an expert loan officer today to explore your options.'
      },
      {
        question: 'How much money can I receive?',
        answer: 'Several factors go into determining this amount. A short list includes but is not limited to:<br><br><ul><li>Your age<br><br></li><li>The age of your non-borrowing spouse<br><br></li><li>The appraised value of your home<br><br></li><li>How much of your mortgage you have paid off<br><br></li><li>The current interest rate</li></ul>'
      }
    ]
  },
  personalLoans: {
    title: 'Personal loans',
    shortDescription: 'Recevie funds within days',
    iconImage: personalLoansImage,
    description: 'When you need cash in a hurry, a personal loan is a quick solution. Since a personal loan is not tied to a specific purpose, you can use the funds for anything you need: pay off high-interest debt, start a business, cover unexpected life expenses — pretty much anything you want.',
    solutionId: 'personalLoans',
    applicationButtonText: 'Apply now',
    applicationButtonUrl: 'https://loans.pl-apply.rate.com/grt/dcbbd1e4-9663-4178-83ea-d81643034270',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862167979/rendition/1080p/file.mp4?loc=external&signature=79bec7ca4edaf66d24debe92d193d506d5ef785a7fdf17d7e291f070e3877eab',
    disclosuresUrl: 'https://www.rate.com/app/personal-loans-product-disclosure',
    faq: [
      {
        question: 'What is a personal loan?',
        answer: 'A personal loan is money that you borrow from a lender to use for a variety of purposes.. Personal loans generally have a fixed interest rate that is typically significantly lower than many credit cards. The term for personal loans usually runs from 1 to 5 years.'
      },
      {
        question: 'What is the process of getting a personal loan?',
        answer: 'To apply for a personal loan ……….. The next step is to choose the the amount that you wish to borrow and selecting your loan option. Next, submit your application along with your financial and employment information within the app or through a loan officer. If you get approved, we\'ll work with you to get the funds to you in as little as 1 business day.<br><br>We offer near instantaneous approval, and it is possible for funds to be received in 1-2 business days!'
      },
      {
        question: 'How much can I borrow with a personal loan?',
        answer: 'The amount of a personal loan varies widely, depending on your credit score and other eligibility factors. Personal loans can range from as low as $4,000 or as much as $50,000 in most states, again depending on your lender, your financial health, as well as the purpose of the loan.'
      },
      {
        question: 'What interest rates do personal loans have?',
        answer: 'Individual interest rates for personal loans are determined based on the federal interest rate, your credit history, application information, the term of the loan you select, and many other factors'
      },
      {
        question: 'Can I repay a personal loan early?',
        answer: 'Yes. You can repay your personal loan early by making extra payments, by paying more than your minimum monthly payment, or by paying off the balance in one lump sum. Repaying a personal loan early can save you quite a bit of money in interest over the life of your loan.'
      }
    ]
  },
  insurance: {
    title: 'Insurance',
    shortDescription: 'We\'ve got you covered',
    iconImage: insuranceImage,
    description: 'Insurance is all about peace of mind. We offer policies that cover everything from homes to cars and pets. Rather than creating our own insurance policies, we are a digital marketplace for insurance that helps you find the best rates out there.',
    solutionId: 'insurance',
    applicationButtonText: 'Get started',
    applicationButtonUrl: 'https://www.rate.com/insurance',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862167943/rendition/1080p/file.mp4?loc=external&signature=709ef5120125d9ed64269427125e574921b04a730ed43bf5afec623cd773a129',
    disclosuresUrl: 'https://www.rate.com/app/insurance-disclosure',
    faq: [
      {
        question: 'What is a digital marketplace for insurance?',
        answer: 'A digital marketplace is an online storefront where you can shop and compare insurance options from a host of different policies and providers. This allows you to tailor an insurance solution that more closely fits your needs and budget.'
      },
      {
        question: 'What kinds of insurance are available?',
        answer: 'Currently, we offer policies for home, life, pet, and auto insurance.'
      },
      {
        question: 'How does insurance work?',
        answer: 'Insurance is a protective policy that you purchase with a monthly fee, called a premium. If you experience a problem that falls within your insurance policy, you can put in a claim to the insurance company to fund a resolution of the problem, subject to the terms of your insurance policy. Keep in mind that you may have to pay a deductible to contribute a resolution.'
      },
      {
        question: 'What is bundling insurance?',
        answer: 'This is when you choose more than one insurance policy from a single provider to lower the overall price. This can also make the insurance process more convenient since you are dealing with one company instead of several.'
      },
      {
        question: 'Do I have to have homeowner\'s insurance?',
        answer: 'Homeowner\'s insurance is not required by law, but lenders will often require it before they approve your loan.'
      }
    ]
  },
  refinance: {
    title: 'Refinance',
    shortDescription: 'Save money with lower payments',
    iconImage: refinanceImage,
    description: 'Sometimes called a \'refi,\' a refinance allows you to renegotiate the terms of your mortgage. The refinance process is generally used to lower the interest rate of your mortgage, lower your monthly payment, or tap into the equity of your home.',
    solutionId: 'refinance',
    applicationButtonText: 'Apply now',
    videoUrl: 'https://player.vimeo.com/progressive_redirect/playback/862168009/rendition/1080p/file.mp4?loc=external&signature=ab304856734682c5379fee004cf8e807d838068ce955119d11e5d4b2d35965bf',
    disclosuresUrl: 'https://www.rate.com/app/same-day-mortgage-disclaimer',
    faq: [
      {
        question: 'What does refinancing mean?',
        answer: 'Refinancing means you are changing the terms of your existing mortgage with the intent of making the terms of your mortgage more advantageous to you in some way. This is most commonly achieved by securing a better interest rate, lowering your monthly payment, or taking cash out of your home\'s equity. Refinancing also allows you to change the type of mortgage loan you have — for example, if you have a variable rate mortgage, you can explore refinancing into a fixed rate mortgage.'
      },
      {
        question: 'Are there different kinds of refinance options?',
        answer: 'Yes. The options vary depending on what you want to accomplish with your refinance. Typically, it is one of three objectives:<br><br><ul><li>A reduction in monthly payments<br><br></li><li>A better rate for your mortgage<br><br></li><li>A withdrawal of cash from the equity of your home.</li></ul>'
      },
      {
        question: 'What is the process of a refinance?',
        answer: 'If you\'ve gone through the home loan process before, the refinance process will seem familiar to you. Put in your application, supply all the necessary documentation, and go through the process step-by-step with your loan officer until such time as you close on your loan.'
      },
      {
        question: 'How long does this process take?',
        answer: 'The refinance process usually takes a little less time than a home loan. On average, you will close your refinanced mortgage in about 30-45 days, although with our Same Day Mortgage, that can be done a lot faster.'
      },
      {
        question: 'When is the best time to refinance?',
        answer: 'If you are refinancing to get a better rate, it\'s recommended that your new mortgage rate be a minimum of 0.5% lower than your current rate to see significant savings over the life of your loan. If you are refinancing to lower your monthly payment or take cash out of your mortgage, we recommend starting the refinance process at least two months before you need the reduction in payments or the balance of funds.'
      }
    ]
  },
  cashoutRefinance: {
    title: 'Cash-out refinance',
    shortDescription: 'Transform home equity into cash',
    iconImage: cashoutRefinanceImage,
    solutionId: 'refinance',
  },
};
