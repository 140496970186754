import { SingleColumnContent } from '../../components/layout/Layout';
import { useUser } from '../../features/user/userSlice';
import { useIsLoadingLoans } from '../../hooks/useIsLoadingLoans';
import { DashboardSavedHomesComponent } from '../homeSearch/SavedHomes';
import { LoanCardSkeletonLoader, RateAppCallout } from '../loans/Loans';
import DashboardRateAlert from './DashboardRateAlert';
import DashboardLoans from './DashboardLoans';
import DashbaordFinancialSolutions from './DashboardFinancialSolutions';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';

const DashboardContent = () => {
  const user = useUser();
  const config = getConfigFromStore();
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];
  return (
    <div>
      <h1 className='mb-4 mt-2 text-marketing-xs md:text-marketing-md'>Welcome{user?.name?.first && <span className='OneLinkNoTx'>, {user.name.first}</span>}</h1>
      <DashboardLoans />
      <DashboardRateAlert className='mt-12' />
      {isHomeSearchEnabled && <DashboardSavedHomesComponent className='mt-12' />}
      <RateAppCallout />
      <DashbaordFinancialSolutions className='mt-12' />
    </div>
  );
};

const Dashboard = () => {
  const loadingLoans = useIsLoadingLoans();
  return (
    <SingleColumnContent>
      {loadingLoans ? (
        <LoanCardSkeletonLoader />
      ) : (
        <DashboardContent />
      )}
    </SingleColumnContent>
  );
};

export default Dashboard;
