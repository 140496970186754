import { useKeenSlider } from 'keen-slider/react';
import Button from '../button/Button';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const Carousel = (
  {
    items,
    className,
    showButtons = true,
    showDots = false,
  }:
  {
    items: React.ReactNode[],
    className?: string,
    showButtons?: boolean,
    showDots?: boolean,
  }
) => {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      mode: 'snap',
      slides: {
        perView: 1,
        spacing: 0,
      },
      breakpoints: {
        '(min-width: 640px)': {
          slides: { perView: 1, spacing: 5 },
        },
        '(min-width: 990px)': {
          slides: { perView: 2, spacing: 10 },
        },
      },
      created: (instance) => {
        const perView = (instance.options.slides as any)?.perView;
        const slidesLength = instance.slides.length;
        const totalDots = Math.ceil(slidesLength / perView);
        setPerView(perView);
        setDotArray(new Array(totalDots).fill(0));
      },
      optionsChanged: (options) => {
        const perView = (options.options.slides as any)?.perView;
        const slidesLength = options.slides.length;
        const totalDots = Math.ceil(slidesLength / perView);
        setPerView(perView);
        setDotArray(new Array(totalDots).fill(0));
      },
      slideChanged: (slide) => {
        const currentSlide = slide.track.details.rel;
        setCurrentSlide(currentSlide);
      },
    },
    [],
  );

  const [dotArray, setDotArray] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perView, setPerView] = useState(1);

  useEffect(() => {
    const currentPage = Math.floor(currentSlide / perView);
    setCurrentPage(currentPage);
  }, [perView, currentSlide])

  const handlePrev = () => {
    instanceRef.current?.prev();
  };

  const handleNext = () => {
    instanceRef.current?.next();
  };

  const handleDotClicked = (index: number) => () => {
    instanceRef.current?.moveToIdx(index * perView);
  };

  const handleDotKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter') {
      instanceRef.current?.moveToIdx(index * perView);
    }
  };

  return (
    <div className={cn(className, 'flex flex-col')}>
      <div className={cn('flex flex-row items-center')}>
        {showButtons && (
          <Button
            className='hidden lg:flex items-center justify-center bg-white w-10 h-10 rounded-full shadow-lg-full relative z-10 left-5'
            buttonType='icon'
            iconPlacement='left'
            iconName='chevron-left-small'
            text='previous'
            onClick={handlePrev}
            tabIndex={0}
          />
        )}
        <div ref={sliderRef} className='keen-slider'>
          {items.map((item, index) => {
            return (
              <div key={index}>
                {item}
              </div>
            );
          })}
        </div>
        {showButtons && (
          <Button
            className='hidden lg:flex items-center justify-center bg-white w-10 h-10 rounded-full shadow-lg-full relative z-10 right-5'
            buttonType='icon'
            iconPlacement='left'
            iconName='chevron-right-small'
            text='next'
            onClick={handleNext}
            tabIndex={0}
          />
        )}
      </div>
      {showDots && (
        <div className='flex flex-row justify-center mt-4 space-x-2'>
          {dotArray.map((_, index) => {
            return (
              <div
                key={index}
                className={cn('rounded-full bg-body-100 w-3 h-3 cursor-pointer', { 'opacity-50': index !== currentPage })}
                onClick={handleDotClicked(index)}
                onKeyDown={event => handleDotKeyDown(event, index)}
                tabIndex={0}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
