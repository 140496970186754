import { useEffect, useRef } from 'react';
import { Task, TaskDocument } from '../../../features/tasks/types';
import { useTaskText } from '../../../hooks/useTaskText';
import Alert from '../../ui/alert/Alert';
import DownloadFile from '../../ui/upload/DownloadFile';
import { DefaultTaskBodyDescription } from './Task';
import { log } from '../../../utils/logger';
import { sendEvent } from '../../../features/analytics/sendEvent';

const TaskCompletedDocuments = ({
  loanGuid,
  task,
  showTaskDescription = true,
}: {
  loanGuid: string;
  task:
    | Task<'data_lox'>
    | Task<'credit_inquiry_lox'>
    | Task<'affirmations'>
    | Task<'document_signing'>
    | Task<'interpretation_ack'>;
  showTaskDescription?: boolean;
}) => {
  const noDocumentsLogged = useRef<boolean>(false);
  const { 'task-id': taskId, 'task-type': taskType, 'task-title': taskTitle, documents } = task;
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const numDocuments = documents?.length;

  useEffect(() => {
    if (!noDocumentsLogged.current && taskType === 'document_signing' && !numDocuments) {
      noDocumentsLogged.current = true;
      log({
        loanGuid,
        taskId,
        message: `No documents shown for completed documents task ${JSON.stringify({ numDocuments, taskType, taskTitle })}`,
      });
    }
  }, [loanGuid, numDocuments, taskType, taskId, taskTitle]);

  const NoDocuments = () =>
    taskType !== 'document_signing' ? (
      <Alert type='info' description='Document creation in process.' showClose={false} />
    ) : (
      <p>No documents found for the task.</p>
    );

  const onViewDocument = (doc: TaskDocument) => {
    sendEvent('documentView', { location: 'tasks', documentId: doc.id, taskId });
    log({ loanGuid, taskId, level: 'info', message: `View document id '${doc.id}'` });
  };

  const onDownloadDocument = (doc: TaskDocument) => {
    sendEvent('documentDownload', { location: 'tasks', documentId: doc.id, taskId });
    log({ loanGuid, taskId, level: 'info', message: `View document id '${doc.id}'` });
  };

  return (
    <>
      {showTaskDescription && <DefaultTaskBodyDescription taskDescription={taskDescription} />}
      <div className='flex flex-col'>
        {documents &&
          documents.map(doc => {
            return (
              <div key={doc.id}>
                <DownloadFile
                  filename={doc.title}
                  fileUrl={doc.href}
                  showDownloadIcon={true}
                  onFilenameClick={() => onViewDocument(doc)}
                  onDownloadClick={() => onDownloadDocument(doc)}
                />
              </div>
            );
          })}
        {!numDocuments && <NoDocuments />}
      </div>
    </>
  );
};

export default TaskCompletedDocuments;
